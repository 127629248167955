<template>
  <div class="form-signin">
    <form v-on:submit.prevent="login()">
        <div class="text-center mb-2">
          <img class="img-signin" src="@/assets/images/logo-large-no-background.png" alt="logotype" />
        </div>

        <!--p class="text-white text-right">
          Don't have an account yet?  <router-link to="/auth/signup">Sign Up</router-link>
        </p-->

        <div v-if="hasErrors" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>

        <div class="form-label-group">
          <input
            type="email"
            id="pas"
            class="form-control"
            placeholder="Username"
            required
            autofocus
            autocomplete="username"
            v-model="email"
          >
          <label for="inputUsername">Email</label>
        </div>

        <div class="form-label-group">
          <input
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            autocomplete="current-password"
            required
            v-model="password">
          <label for="inputPassword">Password</label>
        </div>
        <p class="text-white text-right" style="font-size: 12px">
          <router-link to="/auth/forgotten-password">Forgot your password?</router-link>
        </p>

        <button class="btn btn-lg btn-primary btn-block" type="submit" :disabled="isLoading">Sign in</button>

        <p class="mt-5 mb-3 text-center text-white">Copyright © 2017–{{ new Date().getFullYear() }} Percepio AB. All Rights Reserved.</p>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    email: '',
    password: '',
    errcode: '',
    errorMessage: '',
    hasErrors: false
  }),
  computed: {
    errorCode: function () {
      return this.$store.state.auth.errcode
    }
  },
  methods: {
    login: function () {
      this.isLoading = true
      this.resetErrors()

      this.$store.dispatch('signIn', {
        Username: this.email,
        Password: this.password
      })
    },
    resetErrors: function () {
      this.errorCode = ''
      this.errorMessage = ''
      this.hasErrors = false
    }
  },
  watch: {
    errorCode () {
      if (this.errorCode !== '') {
        if (this.errorCode === '"NotAuthorizedException"') {
          this.errorMessage = 'Incorrect username or password'
        } else if (this.errorCode === '"UserNotFoundException"') {
          this.errorMessage = 'User not found'
        } else if (this.errorCode === '"UserNotConfirmedException"') {
          this.errorMessage = 'User registration not confirmed'
        } else if (this.errorCode === '"LimitExceededException"') {
          this.errorMessage = 'Attempt limit exceeded, please try after some time'
        } else {
          this.errorMessage = 'An error has occured!'
        }
        this.hasErrors = true
      } else {
        this.hasErrors = false
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
    <v-navigation-drawer v-model="drawer" app>
    <v-list density="compact" nav>
      <v-list-item style="text-decoration: none;" title="Dashboard" to="/" prepend-icon="mdi-view-dashboard"/>

      <v-list-item style="text-decoration: none;" title="Tracealyzer" to="/tracealyzer">
        <template v-slot:prepend>
          <v-icon icon="mdi-chart-gantt"></v-icon>
        </template>
      </v-list-item>

      <v-list-item style="text-decoration: none;" title="Help" to="/help" prepend-icon="mdi-book-open-blank-variant"/>
    </v-list>
  </v-navigation-drawer>
  <v-btn v-if="!drawer" class="floating-menu-button" icon="mdi-menu" color="primary" @click.stop="drawer = !drawer"></v-btn>
</template>

<script>
import { VList, VListItem, VIcon, VNavigationDrawer, VBtn } from 'vuetify/components'

export default {
  data: () => ({
    isDeveloper: false,
    drawer: null
  }),
  components: {
    VList,
    VListItem,
    VIcon,
    VNavigationDrawer,
    VBtn
  },
  mounted () {
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>

<style>
.v-list-item-title {
  white-space: normal!important;
}
.v-list-group {
  --prepend-width: 4px!important;
}
.floating-menu-button{
  position:fixed;
  bottom:10px;
  left:10px;
}
</style>

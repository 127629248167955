<template>
  <div>

    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <h2 class="list-page width-actions">
      Users
      <button class="btn btn-primary float-right" data-toggle="modal" data-target="#viewUser">Add new</button>
    </h2>

    <spinner v-if="isLoading"></spinner>

    <table class="table table-striped table-sm" v-if="!isLoading">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ getRoleDescription(user.role) }}</td>
          <td>
            <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#viewUser" :data-user="JSON.stringify(user)">
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div class="modal fade" id="viewUser" tabindex="-1" role="dialog" aria-labelledby="viewTrace" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="!isSelectedUserNew">{{ selectedUserName }}</h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="isSelectedUserNew">New User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="inputName">Name</label>
                <input type="email" class="form-control" id="inputName" aria-describedby="emailHelp" placeholder="Enter username" v-model="selectedUser.name">
              </div>

              <div class="form-group">
                <label for="inputEmail">Email</label>
                <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" v-model="selectedUser.email" :readonly="!isSelectedUserNew">
              </div>

              <div class="form-group">
                <label for="inputRole">Role</label>
                <select class="form-control" id="inputRole" v-model="selectedUser.role" :disabled="selectedUser.role == 'owner'">
                  <option value="owner" :disabled="selectedUser.role !== 'owner'">Owner</option>
                  <!--option value="contributor">Contributor</option-->
                  <option value="viewer">Viewer</option>
                </select>
              </div>

              <div class="form-check" style="margin-top:20px" v-if="isSelectedUserNew">
                <input type="checkbox" class="form-check-input" id="inputConfirm" aria-describedby="emailHelp" v-model="confirmedCreate">
                <label class="form-check-label" for="inputConfirm">I accept the <router-link :to='{ name: "Privacy"}'>Privacy Policy</router-link> in order to create this account</label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-danger" v-on:click.prevent="deleteUser" v-if="isUserDeletable()">Delete</button>
            <button type="submit" class="btn btn-primary" v-on:click.prevent="submitUser" :disabled="isSelectedUserNew && !confirmedCreate">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    isLoading: true,
    users: {},
    selectedUser: {
      name: '',
      email: '',
      role: 'viewer'
    },
    selectedUserName: 'No User',
    confirmedCreate: false,
    isSelectedUserNew: false,
    hasErrors: false,
    errorMessage: ''
  }),
  mounted () {
    const that = this
    that.getUserList()

    // Modal bootstrap fix
    $('#viewUser').on('show.bs.modal', function (event) {
      // make sure Confirm must be checked every time
      that.confirmedCreate = false

      if ($(event.relatedTarget).data('user')) {
        that.selectedUser = $(event.relatedTarget).data('user')
        that.selectedUserName = that.selectedUser.name
        that.isSelectedUserNew = false
      } else {
        that.isSelectedUserNew = true
      }
    })

    // Reset modal close
    $('#viewUser').on('hidden.bs.modal', function (event) {
      that.selectedUser = {
        name: '',
        email: '',
        role: 'viewer'
      }

      that.isSelectedUserNew = false
    })
  },
  methods: {
    /**
     * Translate role description
     */
    getRoleDescription: function (role) {
      if (!role || role.length <= 0) {
        return 'disabled'
      }

      return role
    },
    /**
     * Get user list
     */
    getUserList: function () {
      window.axios.get(this.$store.state.adminEndpoint + '/users').then(response => {
        this.isLoading = false
        this.users = response.data
      }).catch(error => {
        this.isLoading = false
        window.console.log(error)
        this.hasErrors = true
        this.errorMessage = error.message
      })
    },
    /**
     * Submit / Update user.
     */
    submitUser: function () {
      if (this.isSelectedUserNew) {
        window.axios.post(this.$store.state.adminEndpoint + '/users', this.selectedUser).then(response => {
          $('#viewUser').modal('hide')
          this.getUserList()
        }).catch(error => {
          window.console.log(error)
          this.hasErrors = true
          this.errorMessage = error.message
        })
      } else {
        window.axios.put(this.$store.state.adminEndpoint + '/users', this.selectedUser).then(response => {
          $('#viewUser').modal('hide')
          this.getUserList()
          $('#viewUser').modal('hide')
        }).catch(error => {
          window.console.log(error)
          this.hasErrors = true
          this.errorMessage = error.message
        })
      }
    },
    /**
     * Delete user
     */
    deleteUser: function () {
      window.axios.delete(this.$store.state.adminEndpoint + '/users', {
        params: {
          email: this.selectedUser.email
        }
      }).then(response => {
        this.isLoading = false
        this.users = response.data

        this.getUserList()
        $('#viewUser').modal('hide')
      })
    },
    isUserDeletable: function () {
      return (
        !this.isSelectedUserNew &&
        this.selectedUser.email !== '' &&
        this.$store.state.auth.username !== this.selectedUser.email
      )
    }
  },
  components: {
    spinner
  }
}
</script>

<template>
  <div>
    <h2 class="with-actions">
      Tracealyzer Help
    </h2>
    <div class="help-section">
      To use the License Service in Tracealyzer, follow these steps.
    </div>
    <ol>
      <li>
        Download the latest Tracealyzer from the <a href="https://percepio.com/update/">Tracealyzer Updates page</a>.<br/>
        <b>Note!</b> Tracealyzer must be version 4.6.1 or higher, otherwise License Service is not supported.
      </li>
      <li>
        Verify there is at least one available slot by checking the <router-link :to='{ name: "Dashboard"}'>License Information</router-link> page.
      </li>
      <li>
        Start Tracealyzer
        <ul>
          <li>If Tracealyzer is starting for the first time, wait for the Welcome screen and click &quot;Activate License&quot;</li>
          <li>If Tracealyzer already has a valid license, wait for it to start and click &quot;Help&quot; &gt; &quot;Enter License Details...&quot;</li>
          <li>If your current activation key is not working and Tracealyzer asks for an action, click &quot;Change License Configuration&quot;</li>
        </ul>
      </li>
      <li>
        Select &quot;Percepio License Service&quot; and click &quot;Next&quot;
      </li>
      <li>
        Enter your Username, the email that was used for this page.
      </li>
      <li>
        Enter the Password, same as for this page.<br/>
        <b>Note!</b> if you want Tracealyzer to remember the password, check the &quot;Remember Password&quot; box,
        otherwise the password will be requested at each start.
      </li>
      <li>
        Click &quot;Activate&quot; and wait for Tracealyzer to contact the License Service, which takes a few seconds.
        If all goes well, a message box is shown, and Tracealyzer must then be restarted to start using the License Service.<br/>
        If something is wrong, e.g. the Username/Password is incorrect, a message will be shown and the page will stay.
      </li>
      <li>
        If you have any problems, contact <a href="mailto:support@percepio.com">support@percepio.com</a>
      </li>
    </ol>
  </div>
</template>

<template>
  <div>
    <h2>License Information</h2>

    <spinner v-if="isLoading" />

    <div v-if="hasErrors" class="alert alert-danger" role="alert">
      {{ errorMessage }}
    </div>

    <div v-if="!isLoading && !hasErrors && hasLicenses">
      <p>{{ licenseDescription }}</p>

      <div class="table-responsive" v-if="!isLoading && !hasErrors">
        <div v-for="(licRow, licIndex) in collection" :key="licIndex">
          <h3>{{ licRow.name }}</h3>
          <div v-if="licRow.expiresAfter">
            Expires {{ $global.toLocalTime(licRow.expiresAfter) }}
          </div>
          <div v-else>
            License does not expire
          </div>

          <div v-if="licRow.components && licRow.components.length > 0">
            <h4>Contains {{licRow.components.length}} components</h4>
            <p>
              <ul>
                <li v-for="(cmpRow, cmpIndex) in licRow.components" :key="cmpIndex">
                  {{ cmpRow }}
                </li>
              </ul>
            </p>
          </div>

          <h4>License has {{ licRow.slots.length }} slot{{(licRow.slots.length === 1 ? '' : 's')}}</h4>
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th>Number</th>
                <th>Available</th>
                <th>Current User</th>
                <th>Issued</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(slotRow, slotIndex) in licRow.slots" :key="slotIndex">
                <td>{{ slotIndex + 1 }}</td>
                <td>{{ slotRow.slotAvailable ? 'Available' : 'Used' }}</td>
                <td>{{ slotRow.currentUser }}</td>
                <td>{{ slotRow.slotAvailable ? '' : $global.toLocalTime(slotRow.issueTime) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from '../Utils/ClipLoader'

export default {
  data: () => ({
    collection: {},
    isLoading: true,
    hasErrors: false,
    hasLicenses: false,
    errorMessage: '',
    licenseDescription: 'License be loading'
  }),
  mounted () {
    this.getLicenseDetails()
  },
  methods: {
    getLicenseDetails: function () {
      window.axios
        .get(this.$store.state.auth.serviceEndpoint + '/plm')
        .then((response) => {
          if (response.data.code === 200) {
            if (response.data.licenses && response.data.licenses.length > 0) {
              this.licenseDescription = response.data.message
              this.collection = response.data.licenses
              this.hasLicenses = true
            } else {
              this.licenseDescription = response.data.message
              this.hasLicenses = false
            }
            this.isLoading = false
          } else {
            this.errorMessage =
              'Failed to get license details: ' + response.data.message
            this.hasErrors = true
            this.isLoading = false
            this.hasLicenses = false
          }
        })
        .catch((error) => {
          window.console.log(error.message)

          this.errorMessage = 'Failed to get license details: ' + error.message
          this.hasErrors = true
          this.isLoading = false
          this.hasLicenses = false
        })
    }
  },
  components: {
    spinner
  }
}
</script>

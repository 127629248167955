import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Dashboard from '../components/Dashboard/Index.vue'

import Help from '../components/Help/Index.vue'
import Tracealyzer from '../components/Help/Tracealyzer.vue'

import PageNotFound from '../components/Errors/404.vue'

import AuthLogin from '../components/Auth/Login.vue'
import AuthSignup from '../components/Auth/Signup.vue'
import AuthVeryNewPassword from '../components/Auth/VerifyNewPassword.vue'
import ForgottenPassword from '../components/Auth/ForgottenPassword.vue'
import InvalidLogin from '../components/Auth/InvalidLogin.vue'

import AdminUserList from '../components/Admin/UserList.vue'
import Privacy from '../components/Admin/Privacy.vue'

const routes = [
  {
    path: '/',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/login',
    component: AuthLogin,
    name: 'AuthLogin',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/verify-new-password',
    component: AuthVeryNewPassword,
    name: 'AuthVeryNewPassword',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/signup',
    component: AuthSignup,
    name: 'AuthSignup',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/forgotten-password',
    component: ForgottenPassword,
    name: 'ForgottenPassword',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/auth/invalid-login',
    component: InvalidLogin,
    name: 'InvalidLogin',
    meta: {
      requiresAuth: false,
      bodyClass: 'blue-bg'
    }
  },
  {
    path: '/tracealyzer',
    component: Tracealyzer,
    name: 'Tracealyzer',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/help',
    component: Help,
    name: 'Help',
    meta: {
      requiresAuth: true,
      helpPage: true
    }
  },
  {
    path: '/admin',
    component: AdminUserList,
    name: 'Admin',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/privacy',
    component: Privacy,
    name: 'Privacy',
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/:catchAll(.*)*',
    component: PageNotFound,
    name: 'NotFoundPage',
    requiresAuth: false
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Add body class if login/signup page
  if (to.meta.bodyClass) {
    $(document.body).addClass(to.meta.bodyClass)
  } else {
    $(document.body).removeClass()
  }

  /**
   * Handle 404 page
   */
  if (to.name === 'NotFoundPage') {
    next('/')
  }

  if (to.meta.requiresAuth) {
    store.dispatch('tryAutoSignIn', to)

    next()
  } else {
    store.state.auth.isLoading = false
    next()
  }
})

export default router

<template>
  <div>
    <h2 class="with-actions">
      Privacy Policy
    </h2>
    <div class="help-section">
      <p>
        Percepio will store username, email, and the associated License Role for all active users.
        The information can be handled by all users with Owner Role at customer company.
        A backup of the information is also stored for up to 30 days,
        to ensure Percepio can handle the unlikely event of an AWS service failure.
      </p>
      <p>
        In accordance with the General Data Protection Regulation <i>(Regulation EU 2016/279 of 27 April 2016)</i>
        you have the right to request a copy of any information Percepio AB has registered about your person.
        Please contact <a href="mailto:support@percepio.com">support@percepio.com</a> to obtain this information.
        If the information is erroneous, incomplete or irrelevant, you have the right to have your information corrected.
        Finally you also have the right to request that we remove your information from our systems.
      </p>
    </div>

    <div class="help-section" style="margin-top:40px">
      <div class="nav-item">
        <router-link class='nav-link' :to='{ name: "Dashboard"}'>Percepio License Service</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>

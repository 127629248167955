import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/sass/app.scss'
import 'vue-material-design-icons/styles.css'
import * as global from './components/Utils/Global.js'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { load } from 'webfontloader'

window._ = require('lodash')
window.$ = window.jQuery = require('jquery')
window.Popper = require('popper.js').default
require('bootstrap')

/**
 * Ajax.
 */
window.axios = require('axios')

const licensingVuetify = createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#003C69',
          secondary: '#6c99cf'
        }
      }
    }
  }
})
load({
  google: {
    families: ['Roboto:100,300,400,500,700,900&display=swap']
  }
})

const app = createApp(App)
  .use(store)
  .use(router)
  .use(licensingVuetify)

app.config.globalProperties.$global = global
router.isReady().then(() => {
  app.mount('#app')
})

<template>
  <div>
    <h2 class="with-actions">
      Percepio License Service Help
    </h2>
    <div class="help-section">
      Percepio License Service can show your license information, and for multi-user licenses also manage your users.
    </div>
    <router-link :to='{ name: "Dashboard"}'><h4 class="with-actions">License Information</h4></router-link>
    <div class="help-section">
      The License Information page shows your current license information, including when the license will expire,
      how many slots are currently available and who is using them.
    </div>
    <router-link :to='{ name: "Tracealyzer"}'><h4 class="with-actions">Tracealyzer Help</h4></router-link>
    <div>
      Get help on using the License Service in Tracealyzer.
    </div>
    <h4 class="with-actions" v-if="!isAdmin">User Management</h4>
    <router-link :to='{ name: "Admin"}' v-if="isAdmin"><h4 class="with-actions">User Management</h4></router-link>
    <div class="help-section">
      There are two different Roles for a License Service user: Owner, and Viewer.
      <ul>
        <li>Owner: Can use Tracealyzer, see license information, and manage Users</li>
        <li>Viewer: Can use Tracealyzer, and see license information, but not manager Users</li>
      </ul>
      When setting up License Service for you, Percepio will create one user with Owner role who will then manage other users.
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    isDeveloper: false
  }),
  mounted () {
    this.isAdmin = this.$store.state.auth.role === 'Owner'
    this.isDeveloper = (this.$store.state.auth.role === 'Owner' || this.$store.state.auth.role === 'Contributor')
  }
}
</script>

export function queryString (data) {
  if (typeof data === 'string') {
    return data
  }

  const query = []
  for (const key in data) {
    if (typeof data[key] === 'object') {
      for (const nestedKey in data[key]) {
        query.push(
          encodeURIComponent(key) +
            '[' +
            nestedKey +
            ']=' +
            encodeURIComponent(data[key][nestedKey])
        )
      }
    } else if (Object.prototype.hasOwnProperty.call(data, key)) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
  }
  return query.join('&')
}

export function toLocalTime (dateStr) {
  return new Date(dateStr).toLocaleString()
}

export function toLocaleDate (dateStr) {
  return new Date(dateStr).toLocaleDateString()
}

/*
 * Poller class
 * property preGet is a callback that is called
 * before every get request. Can be used to update
 * query parameters (the property 'qp') before
 * every poll.
 */
export default class Poller {
  // count 0 means poll forever
  constructor (interval, count = 0) {
    this.pollInterval = interval
    this.maxPoll = count
    this.runner = null
    this.preGet = null
    this.qp = ''
  }

  stop () {
    clearInterval(this.runner)
  }

  get (url, predicate) {
    const p = this
    let pollCount = 0
    const promise = new Promise(function (resolve, reject) {
      const callback = () => {
        p.pollAPI(url, predicate)
          .then(response => {
            if (response.state === 'AGAIN') {
              if (!p.maxPoll || pollCount++ < p.maxPoll) {
                window.console.log('Poll again')
              } else {
                clearInterval(p.runner)
                reject(new Error('Poll limit reached - giving up'))
              }
            }
            if (response.state === 'DONE') {
              window.console.log('Stop polling - Done')
              clearInterval(p.runner)
              resolve(response.response)
            }
          })
          .catch(error => {
            reject(error)
            clearInterval(p.runner)
          })
      }
      callback()
      p.runner = setInterval(callback, p.pollInterval)
    })
    return promise
  }

  pollAPI (url, predicate) {
    const p = this
    const promise = new Promise(function (resolve, reject) {
      if (p.preGet) {
        p.preGet()
      }
      window.axios.get(url + p.qp)
        .then(response => {
          if (predicate(response)) {
            const result = { state: 'DONE', response: response }
            resolve(result)
          } else {
            // Continue poll
            const result = { state: 'AGAIN', response: response }
            resolve(result)
          }
        })
        .catch(error => {
          window.console.log('Poll error: ' + error.message)
          reject(error)
        })
    })
    return promise
  }
}

<template>
  <div class="form-signin">
    <h2>Invalid Login</h2>

    <div>
      <p>The user is not authorized for Percepio License Service console.</p>
    </div>

    <router-link :to='{ name: "AuthLogin"}'>Back to Login</router-link>
  </div>
</template>
